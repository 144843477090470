import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
import { HTMLTable } from '@blueprintjs/core';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <p>{`This tutorial provides a crash course in using the University of Arizona's High Performance Computing (HPC) clusters.`}</p>
    <h2 {...{
      "id": "outcomes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#outcomes",
        "aria-label": "outcomes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outcomes`}</h2>
    <p>{`By the end of this tutorial, you'll be able to ...`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/using-the-ua-hpc#3-upload-your-file"
        }}>{`move data to`}</a>{` and from the UA HPC`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/using-the-ua-hpc#conda"
        }}>{`create a GPU-accelerated development environment using `}<code parentName="a" {...{
            "className": "language-text"
          }}>{`conda`}</code></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/using-the-ua-hpc#launching-a-jupyter-notebook-with-a-custom-conda-environment"
        }}>{`run GPU-accelerated Jupyter notebooks`}</a>{` `}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/using-the-ua-hpc#building-an-apptainer-image"
        }}>{`create a GPU-accelerated development environment using Apptainer containers`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/using-the-ua-hpc#submitting-a-job"
        }}>{`submit jobs to the cluster`}</a>{` and `}<a parentName="li" {...{
          "href": "/tutorials/using-the-ua-hpc#email-notifications"
        }}>{`receive email notifications about changes in status (completion, failure, etc.)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/using-the-ua-hpc#canceling-a-job"
        }}>{`cancel jobs submitted to the cluster`}</a></li>
    </ul>
    <h2 {...{
      "id": "prerequisites",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#prerequisites",
        "aria-label": "prerequisites permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Prerequisites`}</h2>
    <p>{`Before starting this tutorial, ensure that ...`}</p>
    <ul>
      <li parentName="ul">{`You have a working `}<a parentName="li" {...{
          "href": "/tutorials/docker-basics"
        }}>{`docker installation`}</a></li>
      <li parentName="ul">{`You are `}<a parentName="li" {...{
          "href": "/tutorials/linux-command-line-basics"
        }}>{`comfortable with the basics of the Linux command line`}</a>{` `}</li>
    </ul>
    <p><a parentName="p" {...{
        "href": "https://public.confluence.arizona.edu/display/UAHPC/Training?preview=/86409282/119898817/IntroToHPC.mp4",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Watch this video for a nice overview of the UA HPC`}</a>{`.`}</p>
    <h1 {...{
      "id": "background",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#background",
        "aria-label": "background permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Background`}</h1>
    <p>{`Model development for NLP is an empirical process that is often resource-intensive. Large datasets may require hundreds of gigabytes of storage. Large neural networks may consist of millions or billions of trainable parameters that need to fit in RAM. Even with small datasets and modest models, it's often necessary to run many different experiments each with unique settings (ex. dataset slices, hyperparameter settings, etc.). Conceptually, many of these experiments could be run simultaneously to speed up the model refinement process. `}</p>
    <p>{`When you run into such problems, you need something more than a laptop or a even a custom-built desktop. In industry, the typical solution is to turn to cloud computing service providers. Services like AWS can provide computing resources that can be sized to just about any computing need, but that kind of power and flexibility comes at a premium. Luckily, students of the University of Arizona have free access to their own sizable `}<a parentName="p" {...{
        "href": "https://simple.wikipedia.org/wiki/Supercomputer",
        "target": "_self",
        "rel": "nofollow"
      }}>{`"supercomputer"`}</a>{` which is comprised of multiple High Performance Computing (HPC) clusters. `}</p>
    <div {...{
      "className": "admonition admonition-info alert alert--info"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
              }}></path></svg></span>{`What's a computing cluster?`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`A computing cluster is a group of computers (nodes) networked together in a way that allows resources like CPUS, RAM, GPUs, and storage to be shared. In a high-performance computing cluster, each of these individual computers (nodes) is far more powerful and performant than a consumer-grade machine; however, this sort of power introduces some complexity.  Let's break down the process.`}</p></div></div>
    <h1 {...{
      "id": "using-the-hpc",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#using-the-hpc",
        "aria-label": "using the hpc permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using the HPC`}</h1>
    <ol>
      <li parentName="ol">{`Create an HPC account (you only need to do this once)`}</li>
      <li parentName="ol">{`Configure a development enviroment (contains all task-specific dependencies. You may need one per task or groups of tasks)`}</li>
      <li parentName="ol">{`Move data onto the HPC (you'll probably do this many times for many different taks)`}</li>
      <li parentName="ol">{`Define and run the task (what resources are needed? How should the task be executed?)`}</li>
      <li parentName="ol">{`Accessing the results (move data off of the HPC)`}</li>
    </ol>
    <h2 {...{
      "id": "create-an-account",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#create-an-account",
        "aria-label": "create an account permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Create an account`}</h2>
    <p><a parentName="p" {...{
        "href": "https://public.confluence.arizona.edu/display/UAHPC/Account+Creation#:~:text=To%20register%20for%20an%20HPC,an%20HPC%20account%20for%20you.",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Follow the official documentation to register for an HPC account.`}</a></p>
    <h2 {...{
      "id": "configuring-your-development-environment",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#configuring-your-development-environment",
        "aria-label": "configuring your development environment permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configuring your development environment`}</h2>
    <p>{`There are two options I recommend for configuring development environments on the UA HPC: `}</p>
    <ol>
      <li parentName="ol">{`virtual environments managed with `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`conda`}</code></li>
      <li parentName="ol">{`Apptainer (formerly Singularity) containers`}</li>
    </ol>
    <h3 {...{
      "id": "conda",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#conda",
        "aria-label": "conda permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><code parentName="h3" {...{
        "className": "language-text"
      }}>{`conda`}</code></h3>
    <p><a parentName="p" {...{
        "href": "https://conda.io/projects/conda/en/latest/index.html",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Conda`}</a>{` is a powerful open-source virtual environment and package manager that can simplify complex multi-language software installations.  While `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`conda`}</code>{` environments are not as portable as containers, they're quite flexible and particularly useful `}<strong parentName="p">{`during the development phase`}</strong>{` of some project.`}</p>
    <p>{`We'll use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`conda`}</code>{` to create a virtual environment to house our project dependencies.  `}</p>
    <p>{`Before we start, read through the code block below and take note of each comment.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# NOTE: your home directory has a limit of only 50 GB`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# see https://public.confluence.arizona.edu/display/UAHPC/Storage`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ENV_NAME`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"pytorch"`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ENV_LOC`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$HOME`}</span>{`/conda/environments/`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$ENV_NAME`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# to make it easier to find/discern later, `}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# let's make a description for this env using today's date (MM/DD/YYYY)`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`TODAY`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token variable"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$(`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`date`}</span>{` +`}<span parentName="span" {...{
              "className": "token string"
            }}>{`'%m/%d/%Y'`}</span><span parentName="span" {...{
              "className": "token variable"
            }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`DESC`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$ENV_NAME`}</span>{` environment (created on `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$TODAY`}</span>{`)"`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# we need to load the linux module on the HPC`}</span>{`
module load anaconda
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# create our environment`}</span>{`
conda create -y --prefix `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$ENV_LOC`}</span>{` `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`python`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3.9`}</span>{`
`}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`source`}</span>{` activate `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$ENV_LOC`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# let's install PyTorch with Nvidia GPU support.`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# we'll use exact versions in this example to avoid surprises.`}</span>{`
conda `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` -y `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pytorch`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1.11`}</span>{`.0 `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`torchvision`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0.12`}</span>{` `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`torchaudio`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0.11`}</span>{`.0 -c pytorch 
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# to take advantage of Nvidia GPUs for training and inference,`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# we need to install CUDA `}</span>{`
conda `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` -y `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`cudatoolkit`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`11.3`}</span>{` cudnn -c conda-forge
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# pandas might also be useful`}</span>{`
conda `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` -y pandas
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# as would scikit-learn`}</span>{`
pip `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` -U scikit-learn
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# next, let's install huggingface transformers, tokenizers, and the datasets library`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# we'll install a specific version of transformers `}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# and the latest versions of tokenizers and datasets that are compatible with that version of transformers `}</span>{`
pip `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` -U `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`transformers`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4.17`}</span>{`.0 
pip `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` -U tokenizers datasets
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# let's install a better REPL for Python`}</span>{`
conda `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` -y ipython
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# If we want to use OOD's Jupyter installation, we need to install the following deps:`}</span>{`
conda `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` -y ipykernel
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# these will help us avoid warnings in Jupyter`}</span>{`
pip `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` -U ipywidgets widgetsnbextension
jupyter nbextension `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`enable`}</span>{` --py widgetsnbextension
python -m ipykernel `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` --user --name `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$ENV_NAME`}</span>{` --display-name `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$DESC`}</span>{`"`}</span></code></pre></div>
    <h4 {...{
      "id": "1-define-the-conda-environment",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#1-define-the-conda-environment",
        "aria-label": "1 define the conda environment permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1. Define the `}<code parentName="h4" {...{
        "className": "language-text"
      }}>{`conda`}</code>{` environment`}</h4>
    <p>{`Create a local file called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`my-environment.sh`}</code>{` with the contents of the above code block.`}</p>
    <h4 {...{
      "id": "2-log-in-to-ood",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#2-log-in-to-ood",
        "aria-label": "2 log in to ood permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2. Log in to OOD`}</h4>
    <p>{`Next, let's copy this file to the HPC.  Given that it is just a single file, we'll use the Open On-Demand (OOD) GUI to accomplish this. `}<strong parentName="p">{`Log in to the Open On-Demand (OOD) system using your UA NetID and password`}</strong>{`: `}<a parentName="p" {...{
        "href": "https://ood.hpc.arizona.edu/",
        "target": "_self",
        "rel": "nofollow"
      }}>{`https://ood.hpc.arizona.edu/`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a734a35341cdf8cbc46af189f6217b1f/ee3fb/ood-login.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.62162162162163%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB7UlEQVQ4y61Sy27TUBA1lA9ggxAtQrDhC4A1oHTLHvgb1izZIb4gLCohAWpCoIGoFSRq1NLipKmTFNLGdvzEz2vfHDTXTiHCIAUx0vGM5s4cz0tiSYI0TU/B0xSOH8LyAjDG8Df5NY+QJAkkMmbCUy50Za2K50/LCNMplMExev0RxpqJo5EGVbdgux6G39TCH0hJTsinU6En3wOUHz9D+dETtDsDbDb3sPa6gReVLVTfb6PWaONV7RNevvmIIIxEzjTPnSPMfYjjBHLvK7rKCMdjA4tIRphkhNT/ZrOFE1VFGIUClmPDsCwYpgVtMsFY0zExLeiGAVXXoRsmTNsG57ygZc5xOBjiRNVEIhFRIhEZOclMEwkRqpoOy7bnK4zjGB1Zhq7rmZPzn5sjuwh0DZwLkB3FMWLGQMVJYRTBdlzhDKII4T+AinI9T3BI9HFcF57vww8CEbAI8SzWD0PQTUtUal/p46B7AMdxwRJqgSFeABTPWDK/lP8hYoZ0nLUP21jfaKJSbxWCjvlto43Kxu9v1XoL6++aqG/tZDMcawbOX78H6eIdLK2sYmm5lGGlhLPLJZy7vIqrNx/g2q2HOHPprvCfxuTv0oXbuHLjPkzbpS3H2O8OsSsr+Cz3C7Gzfyjwp/fdLwo6vSMxzx8tvPznNk3l6wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ood login page",
            "title": "ood login page",
            "src": "/static/a734a35341cdf8cbc46af189f6217b1f/fcda8/ood-login.png",
            "srcSet": ["/static/a734a35341cdf8cbc46af189f6217b1f/12f09/ood-login.png 148w", "/static/a734a35341cdf8cbc46af189f6217b1f/e4a3f/ood-login.png 295w", "/static/a734a35341cdf8cbc46af189f6217b1f/fcda8/ood-login.png 590w", "/static/a734a35341cdf8cbc46af189f6217b1f/efc66/ood-login.png 885w", "/static/a734a35341cdf8cbc46af189f6217b1f/ee3fb/ood-login.png 1144w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "3-upload-your-file",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#3-upload-your-file",
        "aria-label": "3 upload your file permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3. Upload your file`}</h4>
    <p>{`From the menu bar, select `}<strong parentName="p">{`Files`}</strong>{` `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mo parentName="mrow">{`→`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\rightarrow`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.3669em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`→`}</span></span></span></span></span>{` `}<strong parentName="p">{`Home Directory`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/cad95a724c029bf5853fb72ff2f858e1/9e7e4/ood-home-directory.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.108108108108105%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAABcklEQVQoz3XMX2/SUBiAcT6hMV5MF82GWMdkOv0Q6rW3u1mCfoOZ+IeSjIVNw8QE0hWhha5NxDDKWk47etEe+hhLExKNb/LLed9z8RSOjxs82XvO5oMSt27foVh6zG5lH2Vnj61imYdKBaW8z3Zpl3LlBUr5GY92nmZ/97cUNu5tc3ezyEauMHVnOLaBZbTpdDU+fKrx8XMd0+hxaZxzaXzDGX7P3j9ss51xhm2swTmjfmtt0KJANj6J38IcmjROTtAuegSeTTxrEF83kd4ZyXVzxTtd394py/kXEq+J9M9I519XwW63y+tXL6lWq/w7KelSkqbLnMz9va/uLBj+GFF/c8Dhu7e8PzpCVVVUtU6tVkPXe3n2/5Oma1lQLiLG5gjbcRj0++i6TqfTQdM0fo3HLG5CbgLBIgwIwwAhBEEg8H2fKIry6KqYBX0hGFoWVs51XabTKZPJJPPTnWNfBThXgpmIkDIhSRLiOEZKuQ4CvwEBNOicUdtb/wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ood home directory",
            "title": "ood home directory",
            "src": "/static/cad95a724c029bf5853fb72ff2f858e1/fcda8/ood-home-directory.png",
            "srcSet": ["/static/cad95a724c029bf5853fb72ff2f858e1/12f09/ood-home-directory.png 148w", "/static/cad95a724c029bf5853fb72ff2f858e1/e4a3f/ood-home-directory.png 295w", "/static/cad95a724c029bf5853fb72ff2f858e1/fcda8/ood-home-directory.png 590w", "/static/cad95a724c029bf5853fb72ff2f858e1/efc66/ood-home-directory.png 885w", "/static/cad95a724c029bf5853fb72ff2f858e1/c83ae/ood-home-directory.png 1180w", "/static/cad95a724c029bf5853fb72ff2f858e1/9e7e4/ood-home-directory.png 2056w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click `}<strong parentName="p">{`Upload`}</strong>{` `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mo parentName="mrow">{`→`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\rightarrow`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.3669em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`→`}</span></span></span></span></span>{` `}<strong parentName="p">{`Browse files`}</strong>{`, and select `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`my-environment.sh`}</code>{` from your local file system.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4168d3a535e3d7d99cbb073292ab5428/64ef5/ood-file-upload.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "27.027027027027025%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAABYlAAAWJQFJUiTwAAABGUlEQVQY02XNvUvDQACG8fz7gpOD6GBtBW1TWxv8GLSbgoiCiwi1fqRdxGhimrtLLpe75hEjguDw44F3eb3b63PGZyOOt9c43VzB31hlv7POoN9m0Gsx7LcbA7+Fv7fFsL/DwO9wGHQ5OfI5DvYIDnYJgi6jUQ+PMoRiAjaE6hnM00//Kh+p8wnoKZgXcDNMfEc8v+Ez+d5CyO5BPOC5GuwS3K8aln/UgKmWSKUbWaZQ2hDeT7kYX3E5eScWGjmbU4RzvFQWpKKgLA26LDFVRbrIiJKMKE55i955fYsai0wgVY6QClVotDForVF5QZSkfKQLPFEYTGWx1lFZi3Xun6qyzZFzjry0pAuBlBIhJHGSIKQkzwuUyvkCyApu1d5S4ZIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ood file upload",
            "title": "ood file upload",
            "src": "/static/4168d3a535e3d7d99cbb073292ab5428/fcda8/ood-file-upload.png",
            "srcSet": ["/static/4168d3a535e3d7d99cbb073292ab5428/12f09/ood-file-upload.png 148w", "/static/4168d3a535e3d7d99cbb073292ab5428/e4a3f/ood-file-upload.png 295w", "/static/4168d3a535e3d7d99cbb073292ab5428/fcda8/ood-file-upload.png 590w", "/static/4168d3a535e3d7d99cbb073292ab5428/efc66/ood-file-upload.png 885w", "/static/4168d3a535e3d7d99cbb073292ab5428/c83ae/ood-file-upload.png 1180w", "/static/4168d3a535e3d7d99cbb073292ab5428/64ef5/ood-file-upload.png 3005w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "4-access-the-hpc-shell",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#4-access-the-hpc-shell",
        "aria-label": "4 access the hpc shell permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`4. Access the HPC shell`}</h4>
    <p>{`Let's run our uploaded script as a job. From the menu bar, select `}<strong parentName="p">{`Clusters`}</strong>{` `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mo parentName="mrow">{`→`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\rightarrow`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.3669em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`→`}</span></span></span></span></span>{` `}<strong parentName="p">{`>_Shell Access`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2184a079361f6880727efc76b8d9bc9d/fa60d/ood-shell-access.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "18.243243243243242%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA6ElEQVQY01WLy07CUBRF+3UGRQUUKgXUmvAPxoHfBe3ty2i5VYNOTHXaAZpgtC12YJvgMlxNxMHKXtn7HE3KiNOzc3abHVr7Xdp6X+Vex2CjtkVts872ThPzZEhvYNLWe+jdAUfmkAPjEKN/rGi0dGr1BlqSJEwmEsuysW2BEI7KFcFFiCUChYymeH6ALQTCcfD9AMdxcVwX1/XU/2g8RitmI6pXDz4iyEJYSOVfWUgxv+P9+Z632S3Zy5RlKv/29ArylV+zTC+huFGbVuYxZRZT5k+U+eM/PrMHynV++2rtVnkWUy1+um9akP7+iAL9GAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ood shell access",
            "title": "ood shell access",
            "src": "/static/2184a079361f6880727efc76b8d9bc9d/fcda8/ood-shell-access.png",
            "srcSet": ["/static/2184a079361f6880727efc76b8d9bc9d/12f09/ood-shell-access.png 148w", "/static/2184a079361f6880727efc76b8d9bc9d/e4a3f/ood-shell-access.png 295w", "/static/2184a079361f6880727efc76b8d9bc9d/fcda8/ood-shell-access.png 590w", "/static/2184a079361f6880727efc76b8d9bc9d/efc66/ood-shell-access.png 885w", "/static/2184a079361f6880727efc76b8d9bc9d/c83ae/ood-shell-access.png 1180w", "/static/2184a079361f6880727efc76b8d9bc9d/fa60d/ood-shell-access.png 1792w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Alternatively, just use this link: `}<a parentName="p" {...{
        "href": "https://ood.hpc.arizona.edu/pun/sys/shell/ssh/default",
        "target": "_self",
        "rel": "nofollow"
      }}>{`https://ood.hpc.arizona.edu/pun/sys/shell/ssh/default`}</a></p>
    <h4 {...{
      "id": "5-select-a-cluster",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#5-select-a-cluster",
        "aria-label": "5 select a cluster permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`5. Select a cluster`}</h4>
    <p>{`The UA HPC is comprised of multiple clusters.  We'll use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ocelote`}</code>{` cluster to create this development environment.  `}<strong parentName="p">{`Type "ocelote" at the command line prompt, and hit `}<code parentName="strong" {...{
          "className": "language-text"
        }}>{`ENTER`}</code></strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/70288a6733166384734d757cf6935f1d/33e10/ood-shell-ocelote.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "77.70270270270271%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAABYlAAAWJQFJUiTwAAABz0lEQVQ4y52Ux67jMAxFvR3YSZze7fTee0OC5P//6Q4OMQr8spq8hSCJpMjLS1LefD5XpVJRuVxWqVRSLpdTPp9XsVi0xblQKPzYP+W8Qcbd2+/36vf7mk6nGo/H5jyKIk0mE3W7XdVqNcVxrHa7rcFgoF6vJ0CwY99qtVSv1023XC7lbbdbc1CtVi1Ko9EwB81m0xBjjAw9ZwISJHlGj20mk5GH5+FwaIhwRJTdbmcoQDibzbTZbAwBiECGDDtQj0Yjyw4A6XRa3nq9tpTJnyhw4vhJ3h2njiun5w6HqVRKYRjKWywWOp1OljZCYLtFxM/zp8zdcWYp4+x+v1vKVNopv13unbdarYwzUofgIAh+GHy7PIiG4MPhYOTCyW8cvR2S8vF4FFzSBrTDb9C9U6bs5/PZ0nbVShbmf9e7KCC73W7mkPYBJcpsNvvVeiNMTgbpUhi3mA4CsCdlbmqSUxSCFIc0J+1CFB67D4I7DpDRxK6p3UdCN/i+r8D39ScIlIJLUr5er9aHjNTj8dDlchHzTTs9n0+9Xi9rKQrHOKJjFMmIjyFqdzSLI8VhRkEmlNfpdGxmicq0YMTcskMFOtJyOlBjaz+Qcxg11frn8C/DF6CHRHgKEAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ood shell for the ocelote cluster",
            "title": "ood shell for the ocelote cluster",
            "src": "/static/70288a6733166384734d757cf6935f1d/fcda8/ood-shell-ocelote.png",
            "srcSet": ["/static/70288a6733166384734d757cf6935f1d/12f09/ood-shell-ocelote.png 148w", "/static/70288a6733166384734d757cf6935f1d/e4a3f/ood-shell-ocelote.png 295w", "/static/70288a6733166384734d757cf6935f1d/fcda8/ood-shell-ocelote.png 590w", "/static/70288a6733166384734d757cf6935f1d/33e10/ood-shell-ocelote.png 844w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "6-request-an-interactive-session",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#6-request-an-interactive-session",
        "aria-label": "6 request an interactive session permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`6. Request an interactive session`}</h4>
    <p>{`The UA HPC is shared resource. Researchers (students, faculty, and affiliates) from UA's many campuses all have access to the HPC.  As large as the HPC is, though, it can't accommodate every request at once.  In order to run anything on the HPC, we always need to specify what resources we're requesting (RAM, CPUs, max runtime, etc.), so that the cluster can plan for our task and allocate the necessary resources.  `}<strong parentName="p">{`Let's request an interactive session with minimal resources using the `}<code parentName="strong" {...{
          "className": "language-text"
        }}>{`interactive`}</code>{` command`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/27814987af4518fc7a5326d1cfb1128f/60708/ood-shell-access-interactive.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "28.37837837837838%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAlklEQVQY052QSwrEIBBEPYHGqDEQiJ+FWQQRTO5/tRpacMhkNy4eVpdQXcqccyC89wghYNs2TNMEIUQ7/4UppWCMgda6IaX8ho2EMgrgnDdGW/0EUqt5nkFN3+EjsPM8kXPGcRyIMWLf9/YFtOQJLSPefr/rmt33jVIKrutCrbUFp5RgrcWyLFjXtWl6CS16el2TTzPxAZeok8TyvzI5AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ood shell interactive session",
            "title": "ood shell interactive session",
            "src": "/static/27814987af4518fc7a5326d1cfb1128f/fcda8/ood-shell-access-interactive.png",
            "srcSet": ["/static/27814987af4518fc7a5326d1cfb1128f/12f09/ood-shell-access-interactive.png 148w", "/static/27814987af4518fc7a5326d1cfb1128f/e4a3f/ood-shell-access-interactive.png 295w", "/static/27814987af4518fc7a5326d1cfb1128f/fcda8/ood-shell-access-interactive.png 590w", "/static/27814987af4518fc7a5326d1cfb1128f/efc66/ood-shell-access-interactive.png 885w", "/static/27814987af4518fc7a5326d1cfb1128f/c83ae/ood-shell-access-interactive.png 1180w", "/static/27814987af4518fc7a5326d1cfb1128f/60708/ood-shell-access-interactive.png 2872w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "admonition admonition-warning alert alert--danger"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "12",
              "height": "16",
              "viewBox": "0 0 12 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M5.05.31c.81 2.17.41 3.38-.52 4.31C3.55 5.67 1.98 6.45.9 7.98c-1.45 2.05-1.7 6.53 3.53 7.7-2.2-1.16-2.67-4.52-.3-6.61-.61 2.03.53 3.33 1.94 2.86 1.39-.47 2.3.53 2.27 1.67-.02.78-.31 1.44-1.13 1.81 3.42-.59 4.78-3.42 4.78-5.56 0-2.84-2.53-3.22-1.25-5.61-1.52.13-2.03 1.13-1.89 2.75.09 1.08-1.02 1.8-1.86 1.33-.67-.41-.66-1.19-.06-1.78C8.18 5.31 8.68 2.45 5.05.32L5.03.3l.02.01z"
              }}></path></svg></span><undefined parentName="h5"><span {...{
              "role": "img",
              "aria-label": "honeybee"
            }}>{`🐝`}</span>{` patient`}</undefined></h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`The HPC is a shared system which goes through periods of heavy use.  Depending on when you make your request, it's possible that it may take a few minutes before the necessary resources become available. `}</p></div></div>
    <h4 {...{
      "id": "7-run-your-script-in-an-interactive-job",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#7-run-your-script-in-an-interactive-job",
        "aria-label": "7 run your script in an interactive job permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`7. Run your script in an interactive job`}</h4>
    <p>{`Let's run the shell script we uploaded to the HPC previously. Type the following command and hit `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ENTER`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`bash`}</span>{` `}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$HOME`}</span>{`/my-environment.sh`}</code></pre></div>
    <p>{`This will take several minutes to run. Once the script completes, you should see output like that depicted in the following screenshot:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1ee450fd1c577bb97161ac411ab2a7c3/1e5d2/ood-conda-env-install-success.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "12.837837837837837%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAkUlEQVQI102O0Q6DIAxF/QII1WQhrqCCjOkSH+DJ//+vu9Blxoeb3p60t+2WZcG+71jXFeyfYMcYxxGNW2vhvRfPzJjnWSoRQSl1SWt9+e44DtRaRdvnhfzOmKZJlkMIEhZjREpJuHNOjjT/n7uzLueMUgrO80Qtv+CYEjZmPIhgiOSjYRhgjBG1vu97UeP3/gt0S1Dw98FtGwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ood shell conda env install success",
            "title": "ood shell conda env install success",
            "src": "/static/1ee450fd1c577bb97161ac411ab2a7c3/fcda8/ood-conda-env-install-success.png",
            "srcSet": ["/static/1ee450fd1c577bb97161ac411ab2a7c3/12f09/ood-conda-env-install-success.png 148w", "/static/1ee450fd1c577bb97161ac411ab2a7c3/e4a3f/ood-conda-env-install-success.png 295w", "/static/1ee450fd1c577bb97161ac411ab2a7c3/fcda8/ood-conda-env-install-success.png 590w", "/static/1ee450fd1c577bb97161ac411ab2a7c3/efc66/ood-conda-env-install-success.png 885w", "/static/1ee450fd1c577bb97161ac411ab2a7c3/c83ae/ood-conda-env-install-success.png 1180w", "/static/1ee450fd1c577bb97161ac411ab2a7c3/1e5d2/ood-conda-env-install-success.png 1630w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "8-end-your-session",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#8-end-your-session",
        "aria-label": "8 end your session permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`8. End your session`}</h4>
    <p>{`To end our interactive session (and avoid wasting allocated time), type `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`exit`}</code>{` and hit `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ENTER`}</code>{`.  You can now close that browser tab and return to `}<a parentName="p" {...{
        "href": "https://ood.hpc.arizona.edu",
        "target": "_self",
        "rel": "nofollow"
      }}>{`https://ood.hpc.arizona.edu`}</a>{`.`}</p>
    <div {...{
      "className": "admonition admonition-success alert alert--success"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "12",
              "height": "16",
              "viewBox": "0 0 12 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M6.5 0C3.48 0 1 2.19 1 5c0 .92.55 2.25 1 3 1.34 2.25 1.78 2.78 2 4v1h5v-1c.22-1.22.66-1.75 2-4 .45-.75 1-2.08 1-3 0-2.81-2.48-5-5.5-5zm3.64 7.48c-.25.44-.47.8-.67 1.11-.86 1.41-1.25 2.06-1.45 3.23-.02.05-.02.11-.02.17H5c0-.06 0-.13-.02-.17-.2-1.17-.59-1.83-1.45-3.23-.2-.31-.42-.67-.67-1.11C2.44 6.78 2 5.65 2 5c0-2.2 2.02-4 4.5-4 1.22 0 2.36.42 3.22 1.19C10.55 2.94 11 3.94 11 5c0 .66-.44 1.78-.86 2.48zM4 14h5c-.23 1.14-1.3 2-2.5 2s-2.27-.86-2.5-2z"
              }}></path></svg></span><undefined parentName="h5">{`Congrats! `}<span {...{
              "role": "img",
              "aria-label": "party popper"
            }}>{`🎉`}</span></undefined></h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`Congratulations!  You've installed your first virtual environment.  Next we'll look at how to use it from Jupyter on OOD.`}</p></div></div>
    <div {...{
      "className": "admonition admonition-note alert alert--secondary"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M6.3 5.69a.942.942 0 0 1-.28-.7c0-.28.09-.52.28-.7.19-.18.42-.28.7-.28.28 0 .52.09.7.28.18.19.28.42.28.7 0 .28-.09.52-.28.7a1 1 0 0 1-.7.3c-.28 0-.52-.11-.7-.3zM8 7.99c-.02-.25-.11-.48-.31-.69-.2-.19-.42-.3-.69-.31H6c-.27.02-.48.13-.69.31-.2.2-.3.44-.31.69h1v3c.02.27.11.5.31.69.2.2.42.31.69.31h1c.27 0 .48-.11.69-.31.2-.19.3-.42.31-.69H8V7.98v.01zM7 2.3c-3.14 0-5.7 2.54-5.7 5.68 0 3.14 2.56 5.7 5.7 5.7s5.7-2.55 5.7-5.7c0-3.15-2.56-5.69-5.7-5.69v.01zM7 .98c3.86 0 7 3.14 7 7s-3.14 7-7 7-7-3.12-7-7 3.14-7 7-7z"
              }}></path></svg></span>{`Feeling impatient? `}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`That took quite awhile, huh? Think this is tedious? Good news: we'll soon learn how to submit such tasks (jobs) to the cluster without needing to request an interactive session. Instead of waiting around for our session to start and the job to complete, the cluster will add this job to a queue and execute it later according to the job's assigned priority and the cluster's available resources. The cluster can even notify us by email when it completes the job.`}</p></div></div>
    <h4 {...{
      "id": "can-i-use-my-environment-on-a-different-cluster",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#can-i-use-my-environment-on-a-different-cluster",
        "aria-label": "can i use my environment on a different cluster permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Can I use my environment on a different cluster?`}</h4>
    <p>{`You can create your `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`conda`}</code>{` environment on any of the HPC clusters available.  Once created, you will be able to activate it on any of the clusters using the following incantation:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ENV_NAME`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"pytorch"`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ENV_LOC`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$HOME`}</span>{`/conda/environments/`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$ENV_NAME`}</span>{`
module load anaconda
`}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`source`}</span>{` activate `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$ENV_LOC`}</span></code></pre></div>
    <div {...{
      "className": "admonition admonition-warning alert alert--danger"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "12",
              "height": "16",
              "viewBox": "0 0 12 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M5.05.31c.81 2.17.41 3.38-.52 4.31C3.55 5.67 1.98 6.45.9 7.98c-1.45 2.05-1.7 6.53 3.53 7.7-2.2-1.16-2.67-4.52-.3-6.61-.61 2.03.53 3.33 1.94 2.86 1.39-.47 2.3.53 2.27 1.67-.02.78-.31 1.44-1.13 1.81 3.42-.59 4.78-3.42 4.78-5.56 0-2.84-2.53-3.22-1.25-5.61-1.52.13-2.03 1.13-1.89 2.75.09 1.08-1.02 1.8-1.86 1.33-.67-.41-.66-1.19-.06-1.78C8.18 5.31 8.68 2.45 5.05.32L5.03.3l.02.01z"
              }}></path></svg></span>{`activating your environment`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`Remember, to make full use of your environment, you'll want to activate in an interactive session or scheduled job.`}</p></div></div>
    <h3 {...{
      "id": "launching-a-jupyter-notebook-with-a-custom-conda-environment",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#launching-a-jupyter-notebook-with-a-custom-conda-environment",
        "aria-label": "launching a jupyter notebook with a custom conda environment permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Launching a Jupyter notebook with a custom conda environment`}</h3>
    <h4 {...{
      "id": "1-launch-a-jupyter-notebook",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#1-launch-a-jupyter-notebook",
        "aria-label": "1 launch a jupyter notebook permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1. Launch a Jupyter notebook`}</h4>
    <p>{`From the OOD menu bar, select `}<strong parentName="p">{`Interactive Apps`}</strong>{` `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mo parentName="mrow">{`→`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\rightarrow`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.3669em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`→`}</span></span></span></span></span>{` `}<strong parentName="p">{`Jupyter Notebook`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/fce3bcad9a04ad2cca2ee4a6aa5f2b85/229ad/ood-jupyter.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.97297297297297%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABYlAAAWJQFJUiTwAAADPUlEQVQ4y22TW28bVRDH94FvwAfga1RFRbSlvCIuoaFJS4BHEFIlJBBfoOK9TZOY0DgkjVKludgOKuWS0KgUpFS0tpu6duzYjq+7vux6vfbevD90TtyoqIw0mrNn5vxn/jOzytjFCd55d4TzH43z/sgo730wyoej4/Lu0sRnjJy/wBtvnuXMW29z+sw5Tp89x4mTp6TvwvjHiPdj4xOMX/qUsYufoEzN/MDVyRDXp2e5dv17rk2GmJya5erkDNOhG9I3HZolPP8jc+F5FhZvEp5fIBLdZH0jylx4QcZPzdxAYCm4VQbdZwy6GQbdNLgF6GfBPgAnT2BlAA/XG+B6Hi+J2ziKd/Jg51Cw4nQKK1jlGJ1iBLO0iV7YwChG6Rxu0s7dxmjmqVRVyuUylUqF4uEh1WoVfwB69SGd4gadYoz2wRpK+uku8YfbPN7doqsXwWsxcDSpgdvA79ex+ya2475UXBCA29fBa4DfAr+N8s3X33L58ld8/sWX3Nu5z/9JAHS7XZrNJrphYDvOsU8kEsDBME5pzq2yO7/MzZVbzIZC3FpeJhLZYG11lVgsSjQa5SCXJZ/Pkzs4IF8oUKlWaTZbmKaJ6/63csXcTfD47u9s/nyH7e1tIpGI1KWlJVZWVlhfW2d/P0Mhn6VcrkhttVpYlkW/36fX62HbtjwLq7R7Fma/jzO89DwPx3EIgkA+EhS9fg+zWqZwWKJUKqOqqowRIsDFt2iHpmko+5kMyUSCp6kUySdPSKVS1Go10uk09Xodw9DpdEwq9eYxZV3X8X1fJnVf6KekLKoSVVjtFm1NNL1Dz7Jk+dlslitXvmM/m8MLkAxe7JkAtD0PfzBgMFTluVPLFXCKcXn2hgu8EfuFV19RKO3dwbKRQ/A8H8d1JYiYqlMrg+8xCAKZQAI2Gj22/kiT2kvSMR38IWDsp7ucfE1h59fb1DWDR/Eke6kM/zxKkHqWoVxVKd3bInDso7V5XqGmtvkruc9v8Sy1liE8EvDBgz859foJdu7/jdoyyGZzqJpGIpEkHA6zuLgobb1Wk/HHlLtdk4Zap9Nu4rpHTRZNNwwDVdXkoORS67qkLawYnPj9hIqlF3QF4L+TIB5KRaUh5wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ood Jupyter notebook",
            "title": "ood Jupyter notebook",
            "src": "/static/fce3bcad9a04ad2cca2ee4a6aa5f2b85/fcda8/ood-jupyter.png",
            "srcSet": ["/static/fce3bcad9a04ad2cca2ee4a6aa5f2b85/12f09/ood-jupyter.png 148w", "/static/fce3bcad9a04ad2cca2ee4a6aa5f2b85/e4a3f/ood-jupyter.png 295w", "/static/fce3bcad9a04ad2cca2ee4a6aa5f2b85/fcda8/ood-jupyter.png 590w", "/static/fce3bcad9a04ad2cca2ee4a6aa5f2b85/efc66/ood-jupyter.png 885w", "/static/fce3bcad9a04ad2cca2ee4a6aa5f2b85/c83ae/ood-jupyter.png 1180w", "/static/fce3bcad9a04ad2cca2ee4a6aa5f2b85/229ad/ood-jupyter.png 1356w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "2-specify-necessary-resources-for-jupyter",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#2-specify-necessary-resources-for-jupyter",
        "aria-label": "2 specify necessary resources for jupyter permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2. Specify necessary resources for Jupyter`}</h4>
    <p>{`Next, we'll specify our requested resources.  As a demonstration, let's try to access a GPU in our development environment:`}</p>
    <p>{`Enter `}<strong parentName="p">{`1`}</strong>{` for `}<strong parentName="p">{`Run time`}</strong>{`, `}<strong parentName="p">{`1`}</strong>{` for `}<strong parentName="p">{`Core count on a single node`}</strong>{`, `}<strong parentName="p">{`4`}</strong>{` for `}<strong parentName="p">{`Memory per core`}</strong>{`, and `}<strong parentName="p">{`1`}</strong>{` for `}<strong parentName="p">{`GPUs required`}</strong>{`.  In order to select `}<strong parentName="p">{`Standard`}</strong>{` for `}<strong parentName="p">{`Queue`}</strong>{` (jobs run uninterrupted), you'll need to provide your course or project-specific group for `}<strong parentName="p">{`PI Group`}</strong>{` group.  If you don't have such a group, use your netid for `}<strong parentName="p">{`PI Group`}</strong>{` group and `}<strong parentName="p">{`windfall`}</strong>{` for `}<strong parentName="p">{`Queue`}</strong>{`.`}</p>
    <div {...{
      "className": "admonition admonition-warning alert alert--danger"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "12",
              "height": "16",
              "viewBox": "0 0 12 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M5.05.31c.81 2.17.41 3.38-.52 4.31C3.55 5.67 1.98 6.45.9 7.98c-1.45 2.05-1.7 6.53 3.53 7.7-2.2-1.16-2.67-4.52-.3-6.61-.61 2.03.53 3.33 1.94 2.86 1.39-.47 2.3.53 2.27 1.67-.02.78-.31 1.44-1.13 1.81 3.42-.59 4.78-3.42 4.78-5.56 0-2.84-2.53-3.22-1.25-5.61-1.52.13-2.03 1.13-1.89 2.75.09 1.08-1.02 1.8-1.86 1.33-.67-.41-.66-1.19-.06-1.78C8.18 5.31 8.68 2.45 5.05.32L5.03.3l.02.01z"
              }}></path></svg></span>{`Windfall can be interrupted`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`All HPC users have unlimited access to the HPC's `}<strong parentName="p">{`windfall`}</strong>{` queue, but this queue takes the lowest priority of all scheduled jobs and can be interrupted by tasks needing to run on queues like `}<strong parentName="p">{`standard`}</strong>{`.  For tasks that rely on uninterrupted access to one or more GPUs, you'll want to avoid using `}<strong parentName="p">{`windfall`}</strong>{`.`}</p></div></div>
    <h4 {...{
      "id": "3-launch-the-jupyter-notebook-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#3-launch-the-jupyter-notebook-server",
        "aria-label": "3 launch the jupyter notebook server permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3. Launch the Jupyter notebook server`}</h4>
    <p>{`Now that we've create a custom development environment using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`conda`}</code>{`, let's launch a Jupyter notebook that uses this environment.`}</p>
    <p>{`Click `}<strong parentName="p">{`Launch`}</strong>{` and wait for your resources to become available.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/be344663fb492efabd811967c2de9985/b6e50/ood-jupyter-job-waiting.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.13513513513513%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAABBklEQVQoz62P20rDQBRF8/8/ooi/IOLlSfTBYqOUSqmGXqaZa5ImM5NkSdIivnh5cMNib845bDjJXFmuFxk3i4zL+ZKr1zcuZgsmQvOsHFNpSaX7AcvU1dzuDHdCk+iy4mX5TiY1qvbsqj1bV7JSBtsEnI/f0wQKQKQps9MTsvsHEt80bFYrdtsNlbN0MTCqa/lVfTdanT6hz8+oJ48kdV2zXq/Z5hJh9qiywe4j5ogsPUXzh/KjPgvzXKKMRWlDrjS51Mgxm3FmjUFrjTEG5xzW2oM7h9Gaqijou47Ee48QgqosadtIDIEYIzGGkXbMkRACw2047r/6QNsevkj6vuc/NPQMfAB0IxqRolP7WgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "you must wait for job resources to become available",
            "title": "you must wait for job resources to become available",
            "src": "/static/be344663fb492efabd811967c2de9985/fcda8/ood-jupyter-job-waiting.png",
            "srcSet": ["/static/be344663fb492efabd811967c2de9985/12f09/ood-jupyter-job-waiting.png 148w", "/static/be344663fb492efabd811967c2de9985/e4a3f/ood-jupyter-job-waiting.png 295w", "/static/be344663fb492efabd811967c2de9985/fcda8/ood-jupyter-job-waiting.png 590w", "/static/be344663fb492efabd811967c2de9985/efc66/ood-jupyter-job-waiting.png 885w", "/static/be344663fb492efabd811967c2de9985/c83ae/ood-jupyter-job-waiting.png 1180w", "/static/be344663fb492efabd811967c2de9985/b6e50/ood-jupyter-job-waiting.png 1862w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "admonition admonition-warning alert alert--danger"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "12",
              "height": "16",
              "viewBox": "0 0 12 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M5.05.31c.81 2.17.41 3.38-.52 4.31C3.55 5.67 1.98 6.45.9 7.98c-1.45 2.05-1.7 6.53 3.53 7.7-2.2-1.16-2.67-4.52-.3-6.61-.61 2.03.53 3.33 1.94 2.86 1.39-.47 2.3.53 2.27 1.67-.02.78-.31 1.44-1.13 1.81 3.42-.59 4.78-3.42 4.78-5.56 0-2.84-2.53-3.22-1.25-5.61-1.52.13-2.03 1.13-1.89 2.75.09 1.08-1.02 1.8-1.86 1.33-.67-.41-.66-1.19-.06-1.78C8.18 5.31 8.68 2.45 5.05.32L5.03.3l.02.01z"
              }}></path></svg></span><undefined parentName="h5"><span {...{
              "role": "img",
              "aria-label": "honeybee"
            }}>{`🐝`}</span>{` patient`}</undefined></h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`During periods of heavy use, it can take awhile before GPUs become available.  This can be very inconvenient for interactive sessions, but it is less of a concern when scheduling jobs that don't need to execute immediately.  Rather than wait, you may want to move on to another task and check back later. `}<a parentName="p" {...{
            "href": "/tutorials/using-the-ua-hpc#5-select-a-cluster"
          }}>{`Alternatively, `}<strong parentName="a">{`try a different cluster`}</strong>{`.`}</a></p></div></div>
    <p>{`Once ready, click the `}<strong parentName="p">{`Connect to Jupyter`}</strong>{` button:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/15f605e3d388ee464c276b54d637c67f/60c1e/ood-jupyter-job-ready.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.810810810810814%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAABIklEQVQoz42PbUvCYBSG9///QwRJfehrH4peLCgdooSVL+l0m8vZnNvcsxedzu0KFcQgwwMXBw7nvs99pIGn0xp90LNbG5Rxg67VwAyHmKH5B0O+oxE1q4psypTNEm9ei4bbYxzbSG40oW5o3DW7PDQ73H588tRWcGc+wUIgEh+x7jvW8wAjMNCFjhZo9Cv3qMUrfGeEFAUBr22DcselpgoqPZ+K4iKCGcdWeH2DVzgjtSwkXwi+jAFTx2YeCdIkAjKyLN2T5OT5IbK9LZCSOCTybOIoZJ4sCeM5TpAwjZd4UYIbJqSrreigaZbtkOqaw2mxQ+FZ5eSxj6xMNrcW6Yplmm16lv+X8DfS+2DKRUnnUtY5f1Gp9p1dmu0Tx5utDX8ArqET7x07VKcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "the interactive session is ready",
            "title": "the interactive session is ready",
            "src": "/static/15f605e3d388ee464c276b54d637c67f/fcda8/ood-jupyter-job-ready.png",
            "srcSet": ["/static/15f605e3d388ee464c276b54d637c67f/12f09/ood-jupyter-job-ready.png 148w", "/static/15f605e3d388ee464c276b54d637c67f/e4a3f/ood-jupyter-job-ready.png 295w", "/static/15f605e3d388ee464c276b54d637c67f/fcda8/ood-jupyter-job-ready.png 590w", "/static/15f605e3d388ee464c276b54d637c67f/efc66/ood-jupyter-job-ready.png 885w", "/static/15f605e3d388ee464c276b54d637c67f/c83ae/ood-jupyter-job-ready.png 1180w", "/static/15f605e3d388ee464c276b54d637c67f/60c1e/ood-jupyter-job-ready.png 1866w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "4-create-a-new-notebook",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#4-create-a-new-notebook",
        "aria-label": "4 create a new notebook permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`4. Create a new notebook`}</h4>
    <p>{`From the right-side menu, select `}<strong parentName="p">{`New`}</strong>{`.  From the next menu, pick the name of your environment (ex. `}<strong parentName="p">{`pytorch environment (created on MM/DD/YYYY)`}</strong>{`):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a71b0e4c06ccc3c82b2e15b08987eb3a/c1c45/ood-jupyter-select-kernel.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "102.02702702702702%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB7UlEQVQ4y5VUi47aQAzk/7+Nng7luKoIQckbQkJem3cy1biEhmjJXVcyDt7dsT1ee4X/XMMwaGXcW/V9j7noLtD+lSOu1dwL1ysnRVHAcRxcr1cYhoH1eg3btpGm6b8I27ZF0zSoqko28jwHbV3XPYT/CaqUguu6CMMQu90O2+1WHDwBBkEgB+j9fD4L4JiiLsr5iqJI7jwA6ZWGJElwOBwk2leXdbzebjcJ5gHIn7quBdSyLDBiHpoC6MBGG6maBiBVppGAvu8L4ZfLRb6n3OgqSl2WpfD8BMgIsyyDaZo4nU4iTN/zPDk8B10EnKZMIEYWx7EcpIzv7xXgy5RZHPLHSLUpjtw9OBy+SDnPoYoS/TCg4TukV7mmF9y1FrCqaxRZBs8yEfg+fNtGpdTfK0y57yQydK3I0DR3XesAO9RNg9Q84vT+A87PLZzPD5jGBubHBvangd+bNxze3xDsf2GIIyAORZeuJY67OYdlVaFKYqSXM9osRZOlqJMYNb/vuo5vaPMMvcrQl4XorlAoC6VJuapQlCXCKJL0l7hjzclzv8Qhi6LyHJ7rwnUcGQZLs29abX1R7p2y3++fukPXu9M3t/hsCMhRNO2O7yxtp3BacCCwhznCOHn4wBkthd+0H49HaUkOkXGfDTHtlD9/HRpuSsLYbAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "select your kernel",
            "title": "select your kernel",
            "src": "/static/a71b0e4c06ccc3c82b2e15b08987eb3a/fcda8/ood-jupyter-select-kernel.png",
            "srcSet": ["/static/a71b0e4c06ccc3c82b2e15b08987eb3a/12f09/ood-jupyter-select-kernel.png 148w", "/static/a71b0e4c06ccc3c82b2e15b08987eb3a/e4a3f/ood-jupyter-select-kernel.png 295w", "/static/a71b0e4c06ccc3c82b2e15b08987eb3a/fcda8/ood-jupyter-select-kernel.png 590w", "/static/a71b0e4c06ccc3c82b2e15b08987eb3a/c1c45/ood-jupyter-select-kernel.png 824w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Let's see if we have access to the GPU. Run the following your first cell:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`nvidia-smi`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3342843f4f5e28272e512f3f9bdcfff0/555cf/ood-jupyter-nvidia-smi.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.72972972972974%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAABUElEQVQoz3WTiY6DMAxE+f9vbNX7IhCOkLScXj1rjdpKRRrZMWEyHocseCe5c+JcLofDQYqiEOeclGWpkfXj8dB8GAaZ51mmaVojGMZR+mFQZHXdSFVV4r2Xuq4VeZ4rIGuaRlJK8nw+lbDvewU5Nd41bZDCeylKL1lV1QJMEcSANeQQAvIQwge6rlsRY1RkVZpkGmcJIYovvRa/lVj+er0+chQS36GEPBDd73dVhwWoMv9Yg7ZttYYdKOYbPORZlkXjSkjbm81Gbrebtk5ua8hOp5Mcj0fZ7/ey2+1ku93K+XzW4RghWAm7GPX06/Wq/qGGiOLL5aJ1yPGLVnlH7SdhTEk3YzatkKOMKb63a/5Rp/WfLdsGyEbuVd+v18IiAzLz07+Ad4UfhCjDE/MM0+0e0h6AmIM4lJ8AAT8J8ZANTBpvbOJ2v8wOAxZQ+275DwyQTbSTx6OsAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "nvidia-smi",
            "title": "nvidia-smi",
            "src": "/static/3342843f4f5e28272e512f3f9bdcfff0/fcda8/ood-jupyter-nvidia-smi.png",
            "srcSet": ["/static/3342843f4f5e28272e512f3f9bdcfff0/12f09/ood-jupyter-nvidia-smi.png 148w", "/static/3342843f4f5e28272e512f3f9bdcfff0/e4a3f/ood-jupyter-nvidia-smi.png 295w", "/static/3342843f4f5e28272e512f3f9bdcfff0/fcda8/ood-jupyter-nvidia-smi.png 590w", "/static/3342843f4f5e28272e512f3f9bdcfff0/efc66/ood-jupyter-nvidia-smi.png 885w", "/static/3342843f4f5e28272e512f3f9bdcfff0/c83ae/ood-jupyter-nvidia-smi.png 1180w", "/static/3342843f4f5e28272e512f3f9bdcfff0/555cf/ood-jupyter-nvidia-smi.png 1960w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Feel free to experiment.  Once you're finished, return to `}<a parentName="p" {...{
        "href": "https://ood.hpc.arizona.edu/pun/sys/dashboard/batch_connect/sessions",
        "target": "_self",
        "rel": "nofollow"
      }}>{`https://ood.hpc.arizona.edu/pun/sys/dashboard/batch_connect/sessions`}</a>{` and click the `}<strong parentName="p">{`Delete`}</strong>{` button for the session you want to terminate to free up its associated resources:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a95a4c510081abf92a67d71cc8bb9dc4/ae2f8/ood-jupyter-shutdown.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.108108108108105%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAABSUlEQVQoz4XOy0ocQRTG8X6yvEEkr5RtcC0hIO7MAwiCgnvNJomJDKPjaM84fbHHrqmuvtX1L92iBCN44EdR5xTfqWiRFiyTfJTma8pNhZCKjaxfDL23iI1CymY0vBvu0aft72xt7/Ph8y4fv+yzEgWzYso0m4wmyQV3ckmiEhK1epHVKTMx4yw55UdyxsX9hOuHOdHvq5i/8wWTRcplUtDrjj/zmMNftxydxxz+vCGTCo/DOIP1BuOH09LbDuUaVFWw3PtKcXRAZHWPtwYIEDxaa27iJavsgVI2VI3m3fIBl+f4siRKsxyxkfS9RluHMYZKrKkqSdN21G2P6iyy0TS9HReH8Mo/2ZEQgrbtsNbig6eQLbsnU3aOp3w7mVHWGu/DuMw6/3/YMz/MPJEoSypVQwg45+mNY7FuuL1XxEU9hjxVePt3rzwCzPURGJp4lfMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "shutdown jupyter",
            "title": "shutdown jupyter",
            "src": "/static/a95a4c510081abf92a67d71cc8bb9dc4/fcda8/ood-jupyter-shutdown.png",
            "srcSet": ["/static/a95a4c510081abf92a67d71cc8bb9dc4/12f09/ood-jupyter-shutdown.png 148w", "/static/a95a4c510081abf92a67d71cc8bb9dc4/e4a3f/ood-jupyter-shutdown.png 295w", "/static/a95a4c510081abf92a67d71cc8bb9dc4/fcda8/ood-jupyter-shutdown.png 590w", "/static/a95a4c510081abf92a67d71cc8bb9dc4/efc66/ood-jupyter-shutdown.png 885w", "/static/a95a4c510081abf92a67d71cc8bb9dc4/c83ae/ood-jupyter-shutdown.png 1180w", "/static/a95a4c510081abf92a67d71cc8bb9dc4/ae2f8/ood-jupyter-shutdown.png 2496w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "containers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#containers",
        "aria-label": "containers permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Containers`}</h3>
    <p>{`The UA HPC does not support running Docker containers, as a user must have root privileges to run Docker containers (See the HPC's official docs](`}<a parentName="p" {...{
        "href": "https://public.confluence.arizona.edu/display/UAHPC/Containers",
        "target": "_self",
        "rel": "nofollow"
      }}>{`https://public.confluence.arizona.edu/display/UAHPC/Containers`}</a>{`).`}</p>
    <p>{`Apptainer (formerly Singularity) is the HPC's supported container technology.  While it's possible to build Apptainer images from scratch, one can also create an Apptainer image directly from a Docker image.  This is the approach we'll take here.`}</p>
    <h4 {...{
      "id": "constructing-a-docker-image",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#constructing-a-docker-image",
        "aria-label": "constructing a docker image permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Constructing a docker image`}</h4>
    <p>{`You can use an existing Docker image or build one yourself outside of the HPC.  `}</p>
    <p>{`Let's create a Docker image that bundles a GPU-accelerated versions of PyTorch and huggingface `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`transformers`}</code>{`.  We'll extend an `}<a parentName="p" {...{
        "href": "https://hub.docker.com/r/pytorch/pytorch",
        "target": "_self",
        "rel": "nofollow"
      }}>{`official PyTorch image`}</a>{` that uses `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`conda`}</code>{` to manage Python:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# print the version of PyTorch used in this image`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` run --platform`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`linux/amd64 -it `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"pytorch/pytorch:1.11.0-cuda11.3-cudnn8-runtime"`}</span>{` python -c `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"import torch;print(torch.__version__)"`}</span></code></pre></div>
    <div {...{
      "className": "admonition admonition-note alert alert--secondary"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M6.3 5.69a.942.942 0 0 1-.28-.7c0-.28.09-.52.28-.7.19-.18.42-.28.7-.28.28 0 .52.09.7.28.18.19.28.42.28.7 0 .28-.09.52-.28.7a1 1 0 0 1-.7.3c-.28 0-.52-.11-.7-.3zM8 7.99c-.02-.25-.11-.48-.31-.69-.2-.19-.42-.3-.69-.31H6c-.27.02-.48.13-.69.31-.2.2-.3.44-.31.69h1v3c.02.27.11.5.31.69.2.2.42.31.69.31h1c.27 0 .48-.11.69-.31.2-.19.3-.42.31-.69H8V7.98v.01zM7 2.3c-3.14 0-5.7 2.54-5.7 5.68 0 3.14 2.56 5.7 5.7 5.7s5.7-2.55 5.7-5.7c0-3.15-2.56-5.69-5.7-5.69v.01zM7 .98c3.86 0 7 3.14 7 7s-3.14 7-7 7-7-3.12-7-7 3.14-7 7-7z"
              }}></path></svg></span>{`the tag matters`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`The tag used in the command above is not arbitrary.  It was selected for its compatibility with the HPC.`}</p></div></div>
    <p>{`Docker images are defined using `}<a parentName="p" {...{
        "href": "https://docs.docker.com/engine/reference/builder/#format",
        "target": "_self",
        "rel": "nofollow"
      }}>{`instructions stored in a dockerfile`}</a>{`. Rather than `}<a parentName="p" {...{
        "href": "https://docs.docker.com/develop/develop-images/baseimages/",
        "target": "_self",
        "rel": "nofollow"
      }}>{`start from scratch`}</a>{`, typically you will add instructions to some existing docker image. Let's look at an example. Save the following to a file called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Dockerfile.gpu`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "dockerfile"
    }}><pre parentName="div" {...{
        "className": "language-dockerfile"
      }}><code parentName="pre" {...{
          "className": "language-dockerfile"
        }}><span parentName="code" {...{
            "className": "token instruction"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`FROM`}</span>{` pytorch/pytorch:1.11.0-cuda11.3-cudnn8-runtime`}</span>{`

`}<span parentName="code" {...{
            "className": "token instruction"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`LABEL`}</span>{` author=`}<span parentName="span" {...{
              "className": "token string"
            }}>{`"Gus Hahn-Powell"`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token instruction"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`LABEL`}</span>{` description=`}<span parentName="span" {...{
              "className": "token string"
            }}>{`"Default image for HLT-oriented UA HPC projects."`}</span></span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# This will be our default directory for subsequent commands`}</span>{`
`}<span parentName="code" {...{
            "className": "token instruction"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`WORKDIR`}</span>{` /app`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# pandas, scikit-learn, ignite, etc.`}</span>{`
`}<span parentName="code" {...{
            "className": "token instruction"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`RUN`}</span>{` conda install -y pandas ignite -c pytorch `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`\\`}</span>{`
    && pip install -U scikit-learn tensorboardX crc32c soundfile`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# SpaCy`}</span>{`
`}<span parentName="code" {...{
            "className": "token instruction"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`RUN`}</span>{` conda install -y spacy cupy -c conda-forge`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#RUN python -m spacy download en_core_web_trf`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# next, let's install huggingface transformers, tokenizers, and the datasets library`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# we'll install a specific version of transformers `}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# and the latest versions of tokenizers and datasets that are compatible with that version of transformers `}</span>{`
`}<span parentName="code" {...{
            "className": "token instruction"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`RUN`}</span>{` pip install -U transformers==4.17.0 `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`\\`}</span>{`
    && pip install -U tokenizers datasets`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# let's include ipython as a better default REPL`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# and jupyter for running notebooks`}</span>{`
`}<span parentName="code" {...{
            "className": "token instruction"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`RUN`}</span>{` conda install -y ipython jupyter ipywidgets widgetsnbextension `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`\\`}</span>{`
    && jupyter nbextension enable --py widgetsnbextension`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# let's define a default command for this image.`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# We'll just print the version for our PyTorch installation`}</span>{`
`}<span parentName="code" {...{
            "className": "token instruction"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`CMD`}</span>{` [`}<span parentName="span" {...{
              "className": "token string"
            }}>{`"python"`}</span>{`, `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"-c"`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"\\"import torch;print(torch.__version__)\\""`}</span>{`]`}</span></code></pre></div>
    <p>{`If you wanted to install additional dependencies, you could add them as lines to the Dockerfile or do something like list them in a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`requirements.txt`}</code>{` file, copy the file to the image during build, and install the dependencies listed in the file line-by-line by adding the following to your Dockerfile:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "dockerfile"
    }}><pre parentName="div" {...{
        "className": "language-dockerfile"
      }}><code parentName="pre" {...{
          "className": "language-dockerfile"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# copy requirements.txt`}</span>{`
`}<span parentName="code" {...{
            "className": "token instruction"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`COPY`}</span>{` requirements.txt .`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# install dependencies line-by-line`}</span>{`
`}<span parentName="code" {...{
            "className": "token instruction"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`RUN`}</span>{` while read requirement; do pip install `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$requirement`}</span>{`; done < requirements.txt`}</span></code></pre></div>
    <h4 {...{
      "id": "publishing-a-docker-image",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#publishing-a-docker-image",
        "aria-label": "publishing a docker image permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Publishing a docker image`}</h4>
    <p>{`In this tutorial, we'll first publish our docker image and then build our Apptainer image on the HPC.  `}<strong parentName="p">{`If an existing Docker image such as `}<a parentName="strong" {...{
          "href": "https://hub.docker.com/repository/docker/uazhlt/ua-hpc",
          "target": "_self",
          "rel": "nofollow"
        }}><code parentName="a" {...{
            "className": "language-text"
          }}>{`uazhlt/ua-hpc:latest`}</code></a>{` already meets your needs, you can skip the next step`}</strong>{`.  If you need to build your own custom image, the simplest next will be to publish it somewhere.  One free option is Docker Hub.  In order to publish an image there, you'll need to `}<a parentName="p" {...{
        "href": "https://hub.docker.com/signup",
        "target": "_self",
        "rel": "nofollow"
      }}>{`create a Docker Hub account`}</a>{`.  `}</p>
    <p>{`After you've created an account, you'll need to `}<a parentName="p" {...{
        "href": "https://docs.docker.com/engine/reference/commandline/login/#examples",
        "target": "_self",
        "rel": "nofollow"
      }}>{`log in`}</a>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# set username to your Docker Hub username`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`USERNAME`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"my-dockerhub-username"`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# this will prompt you to enter your Docker Hub password`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` login -u `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$USERNAME`}</span></code></pre></div>
    <p>{`Once successfully logged in, we're ready to build an image and publish it to Docker Hub. Since the HPC uses AMD64 CPUs, we'll publish an image only for that platform using `}<a parentName="p" {...{
        "href": "https://docs.docker.com/engine/reference/commandline/buildx_build/",
        "target": "_self",
        "rel": "nofollow"
      }}><code parentName="a" {...{
          "className": "language-text"
        }}>{`docker buildx`}</code></a>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# NOTE: change this to match your organization (user ID) on Docker Hub`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ORG`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"uazhlt"`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# the name you want to use for your image`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`IMAGE_NAME`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"ua-hpc"`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# the tag (version identifier) for your image. `}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`TAG`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"latest"`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# --push signals that we'll publish the image immediately after building`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# alternatively, --load will export the built image your local registry.`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Note that it isn't possible to export multi-platform images at this time.`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# -f denotes the path to the dockerfile which defines our image`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` buildx build --platform linux/amd64 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  --push `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  -f Dockerfile.gpu `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
  -t `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`\${ORG}`}</span>{`/`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`\${IMAGE_NAME}`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`\${TAG}`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`.`}</span></code></pre></div>
    <p>{`If all went well, you should see you image at `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`https://hub.docker.com/repository/docker/<org>/<image_name>`}</code>{`.`}</p>
    <p>{`Now we're ready to build an Apptainer image on the HPC!`}</p>
    <h4 {...{
      "id": "building-an-apptainer-image",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#building-an-apptainer-image",
        "aria-label": "building an apptainer image permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Building an Apptainer image`}</h4>
    <p>{`The UA HPC does not support running Docker containers, so we'll need to convert our Docker image into a format that we can run using Apptainer. `}</p>
    <p><a parentName="p" {...{
        "href": "https://apptainer.org/docs/user/main/quick_start.html#quick-installation-steps",
        "target": "_self",
        "rel": "nofollow"
      }}>{`If you're using a Linux machine, you can try installing Apptainer directly`}</a>{`. `}</p>
    <h5 {...{
      "id": "building-an-apptainer-image-using-docker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#building-an-apptainer-image-using-docker",
        "aria-label": "building an apptainer image using docker permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Building an Apptainer image using docker`}</h5>
    <p>{`Installing Apptainer on a non-Linux system is not supported, but there is (always!) a workaround: you can use a Docker image with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`apptainer`}</code>{` installed to build Apptainer images from Docker ("yo dawg I hear you like containers"):`}</p>
    <p>{`For example, to build an Apptainer image from the `}<a parentName="p" {...{
        "href": "https://hub.docker.com/repository/docker/uazhlt/ua-hpc",
        "target": "_self",
        "rel": "nofollow"
      }}><code parentName="a" {...{
          "className": "language-text"
        }}>{`uazhlt/ua-hpc:latest`}</code></a>{` image:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`APPTAINER_IMAGE`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"uazhlt-pytorch-gpu-hpc"`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`DOCKER_IMAGE`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"uazhlt/ua-hpc:latest"`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` run -it --platform linux/amd64 -v `}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$PWD`}</span>{`:/app `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"parsertongue/apptainer:latest"`}</span>{` build `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`\${APPTAINER_IMAGE}`}</span>{`.sif docker://`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`\${DOCKER_IMAGE}`}</span></code></pre></div>
    <h5 {...{
      "id": "building-an-apptainer-image-on-the-hpc",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#building-an-apptainer-image-on-the-hpc",
        "aria-label": "building an apptainer image on the hpc permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Building an Apptainer image on the HPC`}</h5>
    <p>{`We can build our Apptainer image on any of the HPC clusters.  We can either build it interactively or submit a job to build the image.  Since we already walked through the process for requesting an interactive session, let's now look at how to submit a job to the queue:`}</p>
    <h5 {...{
      "id": "pre-built-images",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#pre-built-images",
        "aria-label": "pre built images permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Pre-built images`}</h5>
    <div {...{
      "className": "admonition admonition-info alert alert--info"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
              }}></path></svg></span>{`Existing Apptainer images on the HPC`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`If you'd like to use an existing image, several are available on the UA HPC filesystem under `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`/contrib/singularity/shared/uazhlt/`}</code>{`, including the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`uazhlt-pytorch-gpu-hpc.sif`}</code>{` image that comes from the example `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`Dockerfile`}</code>{` used in this tutorial.
`}<strong parentName="p">{`NOTE`}</strong>{`: this directory is only accessible from a session (i.e., an interactive or non-interactive job).  In other words, you won't be able to see it from the login node.  See `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`/contrib/singularity/shared/uazhlt/README.md`}</code>{` for notes on all available images.`}</p></div></div>
    <h2 {...{
      "id": "submitting-a-job",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#submitting-a-job",
        "aria-label": "submitting a job permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Submitting a job`}</h2>
    <p>{`Who wants to wait around for things to happen?  One of the wonderful things about the HPC is that we can define a task, submit it to the cluster, and get back to important things like scrolling through Twitter.  This is thanks to the `}<a parentName="p" {...{
        "href": "https://slurm.schedmd.com/quickstart.html",
        "target": "_self",
        "rel": "nofollow"
      }}>{`SLURM workload manager`}</a>{`. `}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "575px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/2c01837fa2c1bc928510378f4a610982/039fe/slurm.webp",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "75%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/webp;base64,UklGRsQAAABXRUJQVlA4ILgAAADwBACdASoUAA8APtFUo0uoJKMhsAgBABoJbACdMoRwN6TEgBA2CbwMxoEX5Hlv7aAA/e+Qlim+MfCiV5lpUnY8POTcZsap2KPc7cdMf0i+2y5oDQG6je8gzK+N9rcgs78TDzs2um2U5t4eFpHKGFdwZx+Y4BELVCfByoe4XEhg5zB73H1K2+rp0qA2UPHf0hf5W970VRkNCj6meIo+xMddsl+IThUegiYnl2BZGGQFv242nT0YkgAA')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "slurm",
              "title": "Yes, it's named after that Slurm.  Image from https://futurama.fandom.com/wiki/Slurm",
              "src": "/static/2c01837fa2c1bc928510378f4a610982/039fe/slurm.webp",
              "srcSet": ["/static/2c01837fa2c1bc928510378f4a610982/cbe2e/slurm.webp 148w", "/static/2c01837fa2c1bc928510378f4a610982/3084c/slurm.webp 295w", "/static/2c01837fa2c1bc928510378f4a610982/039fe/slurm.webp 575w"],
              "sizes": "(max-width: 575px) 100vw, 575px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Yes, it's named after that Slurm.  Image from https://futurama.fandom.com/wiki/Slurm`}</figcaption>{`
  `}</figure></p>
    <p>{`Once the necessary resources are available, the cluster will run our task (job).  We can even configure things so that we receive an email when the job start and ends.  All we need to do is write a simple shell script that defines our task (job).  Save the following to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`build-my-image.sbatch`}</code>{` and upload the script to your home directory on the UA HPC.  `}<a parentName="p" {...{
        "href": "https://public.confluence.arizona.edu/display/UAHPC/Transferring+Data",
        "target": "_self",
        "rel": "nofollow"
      }}>{`The official UA HPC docs suggest several options (including Globus)`}</a>{`.  Alternatively, you may use the procedure outlined previously (i.e., `}<strong parentName="p">{`Files`}</strong>{` `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mo parentName="mrow">{`→`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\rightarrow`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.3669em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`→`}</span></span></span></span></span>{` `}<strong parentName="p">{`Home Directory`}</strong>{` from the `}<a parentName="p" {...{
        "href": "https://ood.hpc.arizona.edu",
        "target": "_self",
        "rel": "nofollow"
      }}>{`https://ood.hpc.arizona.edu`}</a>{` menu bar). `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token shebang important"
          }}>{`#!/bin/bash`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# NOTE: Comments that start with SBATCH will pass settings to SLURM. `}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# These directives must be before any other command for SLURM to recognize them.`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# The shell views them as comments.`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#SBATCH --job-name=build-my-image                 # Job name`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#SBATCH --mail-type=BEGIN,FAIL,END                # Mail events (NONE, BEGIN, END, FAIL, ALL)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#SBATCH --mail-user=hahnpowell@email.arizona.edu  # Where to send mail.  This must be your address.	`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#SBATCH --nodes=1                                 # Run our task on a single node	`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#SBATCH --ntasks=1                                # Number of MPI tasks (i.e. processes)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#SBATCH --cpus-per-task=1                         # Number of CPU cores per task.`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#SBATCH --mem=4GB                                 # RAM for task.`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#SBATCH --time=01:00:00                           # Time limit hrs:min:sec`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#SBATCH --output=build-my-image.log               # Standard output and error log`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# <org>/<image>:<tag> on Docker Hub`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`DOCKER_IMAGE`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"uazhlt/ua-hpc:latest"`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`OUTPUT`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$HOME`}</span>{`/uazhlt-pytorch-gpu-hpc.sif

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# For our records, `}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# let's print some information about when the job started and where it was run`}</span>{`
`}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`echo`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Job started:`}<span parentName="span" {...{
              "className": "token entity",
              "title": "\\t"
            }}>{`\\t`}</span><span parentName="span" {...{
              "className": "token variable"
            }}><span parentName="span" {...{
                "className": "token variable"
              }}>{`$(`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`date`}</span><span parentName="span" {...{
                "className": "token variable"
              }}>{`)`}</span></span>{`"`}</span>{`
`}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`echo`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Hostname:`}<span parentName="span" {...{
              "className": "token entity",
              "title": "\\t"
            }}>{`\\t`}</span><span parentName="span" {...{
              "className": "token variable"
            }}><span parentName="span" {...{
                "className": "token variable"
              }}>{`$(`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`hostname`}</span>{` -s`}<span parentName="span" {...{
                "className": "token variable"
              }}>{`)`}</span></span>{`"`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# build our image`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# --force is useful if you want to overwrite a previous build's output`}</span>{`
singularity build --force `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$OUTPUT`}</span>{` docker://`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$DOCKER_IMAGE`}</span>{`

`}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`echo`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Job ended:`}<span parentName="span" {...{
              "className": "token entity",
              "title": "\\t"
            }}>{`\\t`}</span><span parentName="span" {...{
              "className": "token variable"
            }}><span parentName="span" {...{
                "className": "token variable"
              }}>{`$(`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`date`}</span><span parentName="span" {...{
                "className": "token variable"
              }}>{`)`}</span></span>{`"`}</span></code></pre></div>
    <p>{`Now we'll submit our job to queue for scheduling. First, we need to decide which cluster to use to run our job.  That decision might be influenced by a) the types of resources we need (num. CPUs, CPU speed, amount of RAM, num. GPUs, etc.) and resource availability.  `}<a parentName="p" {...{
        "href": "https://ood.hpc.arizona.edu/pun/sys/shell/ssh/default",
        "target": "_self",
        "rel": "nofollow"
      }}>{`From the HPC CLI`}</a>{`, enter the name of the cluster on which you want your job to run (ex. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ocelote`}</code>{`):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/70288a6733166384734d757cf6935f1d/33e10/ood-shell-ocelote.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "77.70270270270271%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAABYlAAAWJQFJUiTwAAABz0lEQVQ4y52Ux67jMAxFvR3YSZze7fTee0OC5P//6Q4OMQr8spq8hSCJpMjLS1LefD5XpVJRuVxWqVRSLpdTPp9XsVi0xblQKPzYP+W8Qcbd2+/36vf7mk6nGo/H5jyKIk0mE3W7XdVqNcVxrHa7rcFgoF6vJ0CwY99qtVSv1023XC7lbbdbc1CtVi1Ko9EwB81m0xBjjAw9ZwISJHlGj20mk5GH5+FwaIhwRJTdbmcoQDibzbTZbAwBiECGDDtQj0Yjyw4A6XRa3nq9tpTJnyhw4vhJ3h2njiun5w6HqVRKYRjKWywWOp1OljZCYLtFxM/zp8zdcWYp4+x+v1vKVNopv13unbdarYwzUofgIAh+GHy7PIiG4MPhYOTCyW8cvR2S8vF4FFzSBrTDb9C9U6bs5/PZ0nbVShbmf9e7KCC73W7mkPYBJcpsNvvVeiNMTgbpUhi3mA4CsCdlbmqSUxSCFIc0J+1CFB67D4I7DpDRxK6p3UdCN/i+r8D39ScIlIJLUr5er9aHjNTj8dDlchHzTTs9n0+9Xi9rKQrHOKJjFMmIjyFqdzSLI8VhRkEmlNfpdGxmicq0YMTcskMFOtJyOlBjaz+Qcxg11frn8C/DF6CHRHgKEAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ood shell for the ocelote cluster",
            "title": "ood shell for the ocelote cluster",
            "src": "/static/70288a6733166384734d757cf6935f1d/fcda8/ood-shell-ocelote.png",
            "srcSet": ["/static/70288a6733166384734d757cf6935f1d/12f09/ood-shell-ocelote.png 148w", "/static/70288a6733166384734d757cf6935f1d/e4a3f/ood-shell-ocelote.png 295w", "/static/70288a6733166384734d757cf6935f1d/fcda8/ood-shell-ocelote.png 590w", "/static/70288a6733166384734d757cf6935f1d/33e10/ood-shell-ocelote.png 844w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Once you've selected a cluster, you will need to execute the following command:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`JOB_SCRIPT`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$HOME`}</span>{`/build-my-image.sbatch
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# use your Net ID`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`GROUP`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`hahnpowell
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# the queue to use. Windfall is unlimited, but may be pre-empted.`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`PARTITION`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`windfall
sbatch --partition`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`windfall `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$JOB_SCRIPT`}</span></code></pre></div>
    <p>{`If you want to run the job on a queue other than `}<strong parentName="p">{`windfall`}</strong>{`, you'll need to specify the group to which the time/resources will be charged:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`JOB_SCRIPT`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$HOME`}</span>{`/build-my-image.sbatch
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# use your Net ID`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`GROUP`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`my-special-group
sbatch --account`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$GROUP`}</span>{` --partition`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`standard `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$JOB_SCRIPT`}</span></code></pre></div>
    <h5 {...{
      "id": "script-extensions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#script-extensions",
        "aria-label": "script extensions permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Script extensions`}</h5>
    <div {...{
      "className": "admonition admonition-info alert alert--info"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
              }}></path></svg></span>{`why `}<code parentName="h5" {...{
            "className": "language-text"
          }}>{`.sbatch`}</code>{`?`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`The extension we use for this script doesn't really matter.  We're using `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`.sbatch`}</code>{` simply to remind ourselves that this script should be run using the `}<a parentName="p" {...{
            "href": "https://slurm.schedmd.com/sbatch.html",
            "target": "_self",
            "rel": "nofollow"
          }}><code parentName="a" {...{
              "className": "language-text"
            }}>{`sbatch`}</code></a>{` command. `}</p></div></div>
    <h5 {...{
      "id": "email-notifications",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#email-notifications",
        "aria-label": "email notifications permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Email notifications`}</h5>
    <div {...{
      "className": "admonition admonition-info alert alert--info"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
              }}></path></svg></span>{`email notifications`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`The `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`--mail-type`}</code>{` directive tells `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`sbatch`}</code>{` what events should trigger an email notification.  If you submit `}<strong parentName="p">{`many`}</strong>{` jobs, you may not want to use `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`--mail-type=ALL`}</code>{`, as it can be too noisy. `}<a parentName="p" {...{
            "href": "https://slurm.schedmd.com/sbatch.html",
            "target": "_self",
            "rel": "nofollow"
          }}>{`See the docs page for `}<code parentName="a" {...{
              "className": "language-text"
            }}>{`sbatch`}</code>{` for a list of all values `}<code parentName="a" {...{
              "className": "language-text"
            }}>{`--mail-type`}</code>{` can take`}</a>{`.  Note that you can only send emails to yourself.  On the UA HPC, you must use your UA email (`}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<netid>@email.arizona.edu`}</code>{`).`}</p></div></div>
    <h5 {...{
      "id": "sbatch-submission-errors",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#sbatch-submission-errors",
        "aria-label": "sbatch submission errors permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><code parentName="h5" {...{
        "className": "language-text"
      }}>{`sbatch`}</code>{` submission errors`}</h5>
    <div {...{
      "className": "admonition admonition-note alert alert--secondary"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M6.3 5.69a.942.942 0 0 1-.28-.7c0-.28.09-.52.28-.7.19-.18.42-.28.7-.28.28 0 .52.09.7.28.18.19.28.42.28.7 0 .28-.09.52-.28.7a1 1 0 0 1-.7.3c-.28 0-.52-.11-.7-.3zM8 7.99c-.02-.25-.11-.48-.31-.69-.2-.19-.42-.3-.69-.31H6c-.27.02-.48.13-.69.31-.2.2-.3.44-.31.69h1v3c.02.27.11.5.31.69.2.2.42.31.69.31h1c.27 0 .48-.11.69-.31.2-.19.3-.42.31-.69H8V7.98v.01zM7 2.3c-3.14 0-5.7 2.54-5.7 5.68 0 3.14 2.56 5.7 5.7 5.7s5.7-2.55 5.7-5.7c0-3.15-2.56-5.69-5.7-5.69v.01zM7 .98c3.86 0 7 3.14 7 7s-3.14 7-7 7-7-3.12-7-7 3.14-7 7-7z"
              }}></path></svg></span>{`error!`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`If you receive an error, it probably means your group name is invalid or doesn't have time allocated for the `}<strong parentName="p">{`standard`}</strong>{` queue.`}</p></div></div>
    <h3 {...{
      "id": "checking-the-status-of-the-job",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#checking-the-status-of-the-job",
        "aria-label": "checking the status of the job permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Checking the status of the job`}</h3>
    <p>{`Our job probably won't run immediately. You can check the status of all jobs you've submitted using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`squeue`}</code>{` command:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# change this to your Net ID`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}><span parentName="span" {...{
              "className": "token environment constant"
            }}>{`USER`}</span></span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`hahnpowell
squeue -u `}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$USER`}</span></code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d81e483668ae6267fd2411dca44d9f7e/c0566/hpc-squeue.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "12.837837837837837%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAABYlAAAWJQFJUiTwAAAApklEQVQI1y3OwQqEMAwEUO+iIgpai7bVbRcRFU1RPPv/3zRLwh5yyLR5TDKOI5ZlwX3fiDHKrOsKYwyICCEEDMOA53mw7zuqqkJd1+j7XnJrLZxzsvNNkqYpvPd43xeMRyKBODuOQ/Bt23BdF+Z5Rp7nAnEBpRSmaZIS53nK34R1+iN8wA+f8AUFD9d1UFrDGiNI0zQoikIaaq2RZRnKspSc8bZt8QMnaFWBufXAXwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "squeue -u hahnpowell",
            "title": "squeue -u hahnpowell",
            "src": "/static/d81e483668ae6267fd2411dca44d9f7e/fcda8/hpc-squeue.png",
            "srcSet": ["/static/d81e483668ae6267fd2411dca44d9f7e/12f09/hpc-squeue.png 148w", "/static/d81e483668ae6267fd2411dca44d9f7e/e4a3f/hpc-squeue.png 295w", "/static/d81e483668ae6267fd2411dca44d9f7e/fcda8/hpc-squeue.png 590w", "/static/d81e483668ae6267fd2411dca44d9f7e/efc66/hpc-squeue.png 885w", "/static/d81e483668ae6267fd2411dca44d9f7e/c83ae/hpc-squeue.png 1180w", "/static/d81e483668ae6267fd2411dca44d9f7e/c0566/hpc-squeue.png 1544w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "admonition admonition-note alert alert--secondary"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M6.3 5.69a.942.942 0 0 1-.28-.7c0-.28.09-.52.28-.7.19-.18.42-.28.7-.28.28 0 .52.09.7.28.18.19.28.42.28.7 0 .28-.09.52-.28.7a1 1 0 0 1-.7.3c-.28 0-.52-.11-.7-.3zM8 7.99c-.02-.25-.11-.48-.31-.69-.2-.19-.42-.3-.69-.31H6c-.27.02-.48.13-.69.31-.2.2-.3.44-.31.69h1v3c.02.27.11.5.31.69.2.2.42.31.69.31h1c.27 0 .48-.11.69-.31.2-.19.3-.42.31-.69H8V7.98v.01zM7 2.3c-3.14 0-5.7 2.54-5.7 5.68 0 3.14 2.56 5.7 5.7 5.7s5.7-2.55 5.7-5.7c0-3.15-2.56-5.69-5.7-5.69v.01zM7 .98c3.86 0 7 3.14 7 7s-3.14 7-7 7-7-3.12-7-7 3.14-7 7-7z"
              }}></path></svg></span>{`cluster-specific`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div"><code parentName="p" {...{
            "className": "language-text"
          }}>{`squeue`}</code>{` will only show you jobs submitted on your current cluster.  `}</p></div></div>
    <h3 {...{
      "id": "canceling-a-job",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#canceling-a-job",
        "aria-label": "canceling a job permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Canceling a job`}</h3>
    <p>{`Imagine you have a job that will take hours (or days) to run, but you realize you've made a mistake.  Rather than waste time and compute resources, you can cancel a running or pending job using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`scancel`}</code>{` command:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`JOBID`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3366998`}</span>{`
scancel `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$JOBID`}</span></code></pre></div>
    <p>{`Try running `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`squeue -u <netid>`}</code>{` again.  The cancelled job should now have disappeared from the list.`}</p>
    {
      /* Scheduler receives a request for resources and creates a job
      Job is put in the queue, where it waits for the resources 
      Job is assigned to the compute nodes and performs computation 
      When job is finished output and error files are created */
    }
    <h3 {...{
      "id": "using-custom-environments",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#using-custom-environments",
        "aria-label": "using custom environments permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using custom environments`}</h3>
    <p>{`Now that you know two methods for creating development environments, let's look at how to submit scripts that use each.`}</p>
    <h4 {...{
      "id": "conda-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#conda-1",
        "aria-label": "conda 1 permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><code parentName="h4" {...{
        "className": "language-text"
      }}>{`conda`}</code></h4>
    <p>{`Create a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.sbatch`}</code>{` script as we saw before:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token shebang important"
          }}>{`#!/bin/bash`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# NOTE: Comments that start with SBATCH will pass settings to SLURM. `}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# These directives must be before any other command for SLURM to recognize them.`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# The shell views them as comments.`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#SBATCH --job-name=test-conda-env                 # Job name`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#SBATCH --mail-type=BEGIN,FAIL,END                # Mail events (NONE, BEGIN, END, FAIL, ALL)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#SBATCH --mail-user=hahnpowell@email.arizona.edu  # Where to send mail.  This must be your address.	`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#SBATCH --nodes=1                                 # Run our task on a single node	`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#SBATCH --ntasks=1                                # Number of MPI tasks (i.e. processes)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#SBATCH --cpus-per-task=1                         # Number of CPU cores per task.`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#SBATCH --mem=4GB                                 # RAM for task.`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#SBATCH --time=01:00:00                           # Time limit hrs:min:sec`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#SBATCH --output=test-conda-env.log               # Standard output and error log`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# For our records, `}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# let's print some information about when the job started and where it was run`}</span>{`
`}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`echo`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Job started:`}<span parentName="span" {...{
              "className": "token entity",
              "title": "\\t"
            }}>{`\\t`}</span><span parentName="span" {...{
              "className": "token variable"
            }}><span parentName="span" {...{
                "className": "token variable"
              }}>{`$(`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`date`}</span><span parentName="span" {...{
                "className": "token variable"
              }}>{`)`}</span></span>{`"`}</span>{`
`}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`echo`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Hostname:`}<span parentName="span" {...{
              "className": "token entity",
              "title": "\\t"
            }}>{`\\t`}</span><span parentName="span" {...{
              "className": "token variable"
            }}><span parentName="span" {...{
                "className": "token variable"
              }}>{`$(`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`hostname`}</span>{` -s`}<span parentName="span" {...{
                "className": "token variable"
              }}>{`)`}</span></span>{`"`}</span>{`


`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# load the linux module on the HPC`}</span>{`
module load anaconda

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# activate your dev environment`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# change the name as appropriate`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ENV_NAME`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"pytorch"`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ENV_LOC`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$HOME`}</span>{`/conda/environments/`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$ENV_NAME`}</span>{`
`}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`source`}</span>{` activate `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$ENV_LOC`}</span>{`

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`OUTPUT_DEST`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$HOME`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# run whatever commands/scripts you need`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# as an example, we'll print our PyTorch version`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# and dump it to a file called "pytorch-version.txt"`}</span>{`
python -c  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"import torch;print(torch.__version__)"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$OUTPUT_DEST`}</span>{`/pytorch-version.txt

`}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`echo`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Job ended:`}<span parentName="span" {...{
              "className": "token entity",
              "title": "\\t"
            }}>{`\\t`}</span><span parentName="span" {...{
              "className": "token variable"
            }}><span parentName="span" {...{
                "className": "token variable"
              }}>{`$(`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`date`}</span><span parentName="span" {...{
                "className": "token variable"
              }}>{`)`}</span></span>{`"`}</span></code></pre></div>
    <div {...{
      "className": "admonition admonition-info alert alert--info"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
              }}></path></svg></span>{`activate your `}<code parentName="h5" {...{
            "className": "language-text"
          }}>{`conda`}</code>{` env`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`The key piece to this process is to activate your environment in you `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`.sbatch`}</code>{` script:`}</p><div parentName="div" {...{
          "className": "gatsby-highlight",
          "data-language": "text"
        }}><pre parentName="div" {...{
            "className": "language-text"
          }}><code parentName="pre" {...{
              "className": "language-text"
            }}>{`# activate your dev environment
# change the name as appropriate
ENV_NAME="pytorch"
ENV_LOC=$HOME/conda/environments/$ENV_NAME
source activate $ENV_LOC`}</code></pre></div></div></div>
    <p>{`Once you have an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.sbatch`}</code>{` script that activates the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`conda`}</code>{` environment, you'll `}<a parentName="p" {...{
        "href": "/tutorials/using-the-ua-hpc#submitting-a-job"
      }}>{`submit it using the `}<code parentName="a" {...{
          "className": "language-text"
        }}>{`sbatch`}</code>{` command as described earlier`}</a>{`.`}</p>
    <h4 {...{
      "id": "container-based",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#container-based",
        "aria-label": "container based permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Container-based`}</h4>
    <p>{`We can execute scripts and commands within a custom Apptainer container as well.
To do so, create a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.sbatch`}</code>{` script as we saw before:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token shebang important"
          }}>{`#!/bin/bash`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# NOTE: Comments that start with SBATCH will pass settings to SLURM. `}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# These directives must be before any other command for SLURM to recognize them.`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# The shell views them as comments.`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#SBATCH --job-name=test-conda-env                 # Job name`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#SBATCH --mail-type=BEGIN,FAIL,END                # Mail events (NONE, BEGIN, END, FAIL, ALL)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#SBATCH --mail-user=hahnpowell@email.arizona.edu  # Where to send mail.  This must be your address.	`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#SBATCH --nodes=1                                 # Run our task on a single node	`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#SBATCH --ntasks=1                                # Number of MPI tasks (i.e. processes)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#SBATCH --cpus-per-task=1                         # Number of CPU cores per task.`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#SBATCH --mem=4GB                                 # RAM for task.`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#SBATCH --time=01:00:00                           # Time limit hrs:min:sec`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#SBATCH --output=test-conda-env.log               # Standard output and error log`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# For our records, `}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# let's print some information about when the job started and where it was run`}</span>{`
`}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`echo`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Job started:`}<span parentName="span" {...{
              "className": "token entity",
              "title": "\\t"
            }}>{`\\t`}</span><span parentName="span" {...{
              "className": "token variable"
            }}><span parentName="span" {...{
                "className": "token variable"
              }}>{`$(`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`date`}</span><span parentName="span" {...{
                "className": "token variable"
              }}>{`)`}</span></span>{`"`}</span>{`
`}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`echo`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Hostname:`}<span parentName="span" {...{
              "className": "token entity",
              "title": "\\t"
            }}>{`\\t`}</span><span parentName="span" {...{
              "className": "token variable"
            }}><span parentName="span" {...{
                "className": "token variable"
              }}>{`$(`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`hostname`}</span>{` -s`}<span parentName="span" {...{
                "className": "token variable"
              }}>{`)`}</span></span>{`"`}</span>{`


`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`IMAGE_FILE`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$HOME`}</span>{`/uazhlt-pytorch-gpu-hpc.sif

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`OUTPUT_DEST`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$HOME`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# run whatever commands/scripts you need using `}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# \`singularity exec $IMAGE_FILE <cmd>\``}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# as an example, we'll print our PyTorch version`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# and dump it to a file called "pytorch-version.txt"`}</span>{`
singularity `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`exec`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$IMAGE_FILE`}</span>{` python -c  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"import torch;print(torch.__version__)"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$OUTPUT_DEST`}</span>{`/pytorch-version.txt

`}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`echo`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Job ended:`}<span parentName="span" {...{
              "className": "token entity",
              "title": "\\t"
            }}>{`\\t`}</span><span parentName="span" {...{
              "className": "token variable"
            }}><span parentName="span" {...{
                "className": "token variable"
              }}>{`$(`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`date`}</span><span parentName="span" {...{
                "className": "token variable"
              }}>{`)`}</span></span>{`"`}</span></code></pre></div>
    <p>{`Once you have an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.sbatch`}</code>{` script that references your image, you'll `}<a parentName="p" {...{
        "href": "/tutorials/using-the-ua-hpc#submitting-a-job"
      }}>{`submit it using the `}<code parentName="a" {...{
          "className": "language-text"
        }}>{`sbatch`}</code>{` command as described earlier`}</a>{`.`}</p>
    <h5 {...{
      "id": "accessing-data-and-mounting-volumes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#accessing-data-and-mounting-volumes",
        "aria-label": "accessing data and mounting volumes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Accessing data and mounting volumes`}</h5>
    <div {...{
      "className": "admonition admonition-info alert alert--info"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
              }}></path></svg></span><code parentName="h5" {...{
            "className": "language-text"
          }}>{`$HOME`}</code>{` etc.`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div"><a parentName="p" {...{
            "href": "https://apptainer.org/docs/user/main/bind_paths_and_mounts.html?highlight=mount#system-defined-bind-paths",
            "target": "_self",
            "rel": "nofollow"
          }}>{`By default, apptainer will auto-mount your home directory and several system paths`}</a>{`.  You can disable this behavior on a per-directory basis using the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`--no-mount`}</code>{` flag (ex. `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`--no-mount <mountname1>,<mountname2>`}</code>{`).  Like Docker, you can bind mount other directories, using the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`--bind`}</code>{` flag (ex. `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`--bind /local/path:/container/path`}</code>{`).`}</p></div></div>
    <h5 {...{
      "id": "gpus-with-apptainer",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#gpus-with-apptainer",
        "aria-label": "gpus with apptainer permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`GPUs with Apptainer`}</h5>
    <div {...{
      "className": "admonition admonition-info alert alert--info"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
              }}></path></svg></span>{`using GPUs`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`In order to use a GPU with apptainer, you must a) alllocate the hardware resource when submitting the job and b) call `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`singularity`}</code>{` with the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`--nv`}</code>{` flag (`}<code parentName="p" {...{
            "className": "language-text"
          }}>{`singularity --nv exec path/to/image.sif nvidia-smi`}</code>{`)`}</p></div></div>
    <h1 {...{
      "id": "interactive-jobs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#interactive-jobs",
        "aria-label": "interactive jobs permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Interactive jobs`}</h1>
    <h3 {...{
      "id": "requesting-a-custom-interactive-session",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#requesting-a-custom-interactive-session",
        "aria-label": "requesting a custom interactive session permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Requesting a custom interactive session`}</h3>
    <div {...{
      "className": "admonition admonition-info alert alert--info"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
              }}></path></svg></span>{`how can I customize an interactive job?`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`Need a GPU?  More RAM? It's possible to run `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`interactive`}</code>{` with a custom request.  Run `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`interactive --help`}</code>{` for details.  Alternatively, you can use the `}<a parentName="p" {...{
            "href": "https://slurm.schedmd.com/salloc.html",
            "target": "_self",
            "rel": "nofollow"
          }}><code parentName="a" {...{
              "className": "language-text"
            }}>{`salloc`}</code>{` command`}</a>{` (directly).`}</p></div></div>
    <h5 {...{
      "id": "no-gpu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#no-gpu",
        "aria-label": "no gpu permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`No GPU`}</h5>
    <p>{`The following command will request a 1-hour interactive session with 1 CPU, and 4 GB of RAM on a single node using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`standard`}</code>{` queue:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# replace with your group`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`GROUP`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"uazhlt"`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`JOB_NAME`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"interactive"`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`PARTITION`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"standard"`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`MEM`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"4GB"`}</span>{`
salloc --job-name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$JOB_NAME`}</span>{` --mem-per-cpu`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$MEM`}</span>{` --nodes`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` --ntasks`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` --time`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`01:00:00 --account`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$GROUP`}</span>{` --partition`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$PARTITION`}</span></code></pre></div>
    <h5 {...{
      "id": "with-a-gpu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#with-a-gpu",
        "aria-label": "with a gpu permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`With a GPU`}</h5>
    <p>{`The following command will request a 1-hour interactive session with 1 CPU, and 4 GB of RAM on a single node using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`standard`}</code>{` queue:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# replace with your group`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`GROUP`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"uazhlt"`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`JOB_NAME`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"interactive"`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`PARTITION`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"standard"`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`MEM`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"4GB"`}</span>{`
salloc --job-name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$JOB_NAME`}</span>{` --mem-per-cpu`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$MEM`}</span>{` --nodes`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` --ntasks`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` --time`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`01:00:00 --account`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$GROUP`}</span>{` --partition`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$PARTITION`}</span></code></pre></div>
    {
      /* ## Examining the results */
    }
    <h2 {...{
      "id": "useful-commands-for-the-hpc",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#useful-commands-for-the-hpc",
        "aria-label": "useful commands for the hpc permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Useful commands for the HPC`}</h2>
    <p>{`The table below lists some commands you may find yourself using frequently on the HPC.`}</p>
    <HTMLTable condensed striped mdxType="HTMLTable">
  <tbody>
    <tr>
        <td>
          <code>interactive</code>
        </td>
        <td>
        </td>
        <td>
          <p>
            Requests an interactive session with fairly minimal resources.  See <code>which interactive</code> for details.
          </p>
        </td>
    </tr>
     <tr>
        <td>
          <code>cluster-busy</code>
        </td>
        <td>
        </td>
        <td>
          <p>
            View a summary of resource utilization on the current cluster.
           </p>
        </td>
    </tr>
    <tr>
      <td>
        <code>node-busy</code>
      </td>
      <td>
      </td>
      <td>
        <p>
          View a summary of resource utilization for each node on the current cluster
        </p>
      </td>
    </tr>
    <tr>
      <td>
        <code>squeue -t PD</code>
      </td>
      <td>
      </td>
      <td>
        <p>
          See what jobs are currently pending (not yet running)
        </p>
      </td>
    </tr>
    <tr>
      <td>
        <code>squeue -t PD -p standard</code>
      </td>
      <td>
      </td>
      <td>
        <p>
          See what jobs in the standard partition are currently pending (not yet running)
        </p>
      </td>
    </tr>
    <tr>
      <td>
        <code>squeue -u hahnpowell</code>
      </td>
      <td>
      </td>
      <td>
        <p>
          See all jobs for the user <b>hahnpowell</b>
        </p>
      </td>
    </tr>
    <tr>
      <td>
        <code>scancel somejobid</code>
      </td>
      <td>
      </td>
      <td>
        <p>
          Cancel a job by its <b>somejobid</b>
        </p>
      </td>
    </tr>
  </tbody>
    </HTMLTable>
    <h1 {...{
      "id": "resources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#resources",
        "aria-label": "resources permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Resources`}</h1>
    <p>{`The following table lists some useful resources to supplement the topics covered in this tutorial.`}</p>
    <HTMLTable condensed striped mdxType="HTMLTable">
  <tbody>
    <tr>
        <td>
          <p>UA HPC</p>
        </td>
        <td>
        </td>
        <td>
          <p>
            <a href="https://docs.hpc.arizona.edu">Official documentation for the UA HPC</a>
          </p>
          <p>
            <a href="https://uarizona.service-now.com/sp?id=sc_cat_item&sys_id=2983102adbd23c109627d90d689619c6&sysparm_category=84d3d1acdbc8f4109627d90d6896191f">Submit a ticket/request help</a>
          </p>
          <p>
            <a href="https://gather.town/app/dVsAprPNBVmI9NpL/hpc-office-hours">Attend HPC "office hours"</a>
          </p>
          <p>
            You can reach the UA HPC team by email using <code>hpc-consult AT list DOT arizona DOT edu</code>
          </p>
        </td>
    </tr>
     <tr>
        <td>
          <p>SLURM workload manager</p>
        </td>
        <td>
        </td>
        <td>
          <p>
            <a href="https://slurm.schedmd.com/">SLURM docs</a>
           </p>
        </td>
    </tr>
    <tr>
      <td>
        <p><code>conda</code></p>
      </td>
      <td>
      </td>
      <td>
        <p>
          Learn some useful <code>conda</code> commands using the <a href="https://docs.conda.io/projects/conda/en/latest/_downloads/843d9e0198f2a193a3484886fa28163c/conda-cheatsheet.pdf">official cheat sheet</a>
        </p>
      </td>
    </tr>
    <tr>
      <td>
        <p>Apptainer container platform</p>
      </td>
      <td>
      </td>
      <td>
        <p>
          <a href="https://apptainer.org/docs/user/main/">Apptainer official docs</a>
        </p>
      </td>
    </tr>
  </tbody>
    </HTMLTable>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      